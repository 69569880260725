import * as React from 'react';
import Chip from '@mui/material/Chip';
import {CardMedia} from '@mui/material';

const cardDataList = [
    {
        id: 1,
        relevance: 90.0,
        source: "./img/projects/vedea1.webp",
        width: 200,
        height: 1000,
        title: "Vedea VR",
        smallTextContent: "During my time at GainPlay Studio we created a VR game for children with amblyopia.",
        largeTextContent: function() {
          return (VedeaCardContent());
      },
        dateFrom: "2022",
        dateEnd: "2023",
        programmingChips: ["Unity", "VR", "Mobile"],
    },

    {
      id: 1,
      relevance: 70.0,
      source: "./img/projects/thewall.webp",
      width: 200,
      height: 1000,
      title: "The Wall",
      smallTextContent: "Game about destroying a wall painting. However the painting is 3d and regenerates?!",
      largeTextContent: function() {
        return (VedeaCardContent());
    },
      dateFrom: "2024",
      dateEnd: "2024",
      programmingChips: ["Unity", "Sentis", "ComputeShader"],
    },

    {
      id: 1,
      relevance: 65.0,
      source: "./img/projects/sandsim.webp",
      width: 200,
      height: 1000,
      title: "Sand Simulation",
      smallTextContent: "Sand Simulation; Cellular Automata running on a compute shader.",
      largeTextContent: function() {
        return (SandSimCardContent());
    },
      dateFrom: "2024",
      dateEnd: "2024",
      programmingChips: ["Unity", "ComputeShader"],
    },

    {
      id: 1,
      relevance: 96.0,
      source: "./img/projects/zalfje.webp",
      width: 200,
      height: 1000,
      title: "Zalfje",
      smallTextContent: "Creating a point-and-click, with multiple minigames, mobile game for children with eczema.",
      largeTextContent: function() {
        return (ZalfjeCardContent());
    },
      dateFrom: "2023",
      dateEnd: "2024",
      programmingChips: ["Unity", "Mobile"],
    },

    {
      id: 1,
      relevance: 89.0,
      source: "./img/projects/surveytoolkit.webp",
      width: 200,
      height: 1000,
      title: "Survey Toolkit",
      smallTextContent: "A Survey Toolkit to create plug-and-play (VR Ready) Surveys.",
      largeTextContent: function() {
        return (SurveyToolkitCardContent());
    },
      dateFrom: "2023",
      dateEnd: "2024",
      programmingChips: ["Unity", "Mobile", "VR", "Package"],
    },

    {
      id: 1,
      relevance: 95.0,
      source: "./img/projects/spacefreight.webp",
      width: 200,
      height: 1000,
      title: "Space Freight Ltd.",
      smallTextContent: "Another GameJam submission! Ludum Dare 54.",
      largeTextContent: function() {
        return (SpaceFreightCardContent());
    },
      dateFrom: "2022",
      dateEnd: "2023",
      programmingChips: ["Unity", "GameJam"],
  },

    {
      id: 1,
      relevance: 100.0,
      source: "./img/projects/dots.webp",
      width: 200,
      height: 1000,
      title: "DOTS-Survivor",
      smallTextContent: "WIP: My take on the Survivor-like genre, a technical experiment limit-testing performance using the Unity ECS framework. Capable of running 10s of thousands enemies.",
      largeTextContent: function() {
        return (DotsSurvivorCardContent());
    },
      dateFrom: "2023",
      dateEnd: "2024",
      programmingChips: ["Unity", "ECS", "DOTS"],
  },

    {
        id: 1,
        relevance: 75.0,
        source: "./img/projects/deployhelperbot.webp",
        width: 200,
        height: 1000,
        title: "Deploy Helper Bot",
        smallTextContent: "Another GameJam submission! Ludum Dare 51.",
        largeTextContent: function() {
            return (DeployHelperBotCardContent());
        },
        dateFrom: "2022",
        dateEnd: "2022",
        programmingChips: ["Unity"],
    },
    {
        id: 1,
        relevance: 80.0,
        source: "./img/projects/nurseinVR.webp",
        width: 200,
        height: 1000,
        title: "NurseInVR",
        smallTextContent: "Applied game for nurses in spe.",
        largeTextContent: function() {
            return (WindesheimCardContent());
        },
        dateFrom: "2022",
        dateEnd: "2022",
        programmingChips: ["Unity", "VR"],
    },

    {
        id: 1,
        relevance: 70.0,
        source: "./img/projects/prompted.webp",
        width: 200,
        height: 1000,
        title: "Prompted",
        smallTextContent: "Generative Art Poster Webstore.",
        largeTextContent: function() {
            return (PromptedCardContent());
        },
        dateFrom: "2022",
        dateEnd: "2022",
        programmingChips: ["React", "Firebase"],
    },
  
    {
      id: 1,
      relevance: 40.0,
      source: "./img/projects/portfolio.webp",
      width: 200,
      height: 1000,
      title: "This site",
      smallTextContent: "A ReactJS + MaterialUI site smashed together in the duration of a weekend.",
      largeTextContent: function() {
        return (ReactCardContent());
    },
      dateFrom: "2021",
      dateEnd: "2022",
      programmingChips: ["React", "Javascript"],
    },

    {
      id: 1,
      relevance: 49.0,
      source: "./img/projects/pathtracer.webp",
      width: 200,
      height: 1000,
      title: "C++ Path Tracer",
      smallTextContent: "Created for a course on Advanced Graphics.",
      largeTextContent: function() {
        return (PathTracerContent());
    },
      dateFrom: "2021",
      dateEnd: "2022",
      programmingChips: ["C++", "Uni"],
    },

    {
      id: 1,
      relevance: 20.0,
      source: "./img/projects/pazooka.webp",
      width: 200,
      height: 1000,
      title: "C++ Highschool Project",
      smallTextContent: "Created a 2d platformer with map editor!!",
      largeTextContent: function() {
        return (PazookaCardContent());
    },
      dateFrom: "2021",
      dateEnd: "2022",
      programmingChips: ["C++", "SFML", "Highschool"],
    },

    {
      id: 1,
      relevance: 5.0,
      source: "./img/projects/pickingsticks.webp",
      width: 200,
      height: 1000,
      title: "First Game Ever!",
      smallTextContent: "My first game ever, Pickin' Sticks. Created back in 2010..",
      largeTextContent: function() {
        return (PickinSticksCardContent());
    },
      dateFrom: "2021",
      dateEnd: "2022",
      programmingChips: ["C++", "SDL", "Highschool"],
    },

    {
      id: 1,
      relevance: 10.0,
      source: "./img/projects/gudcraft.webp",
      width: 200,
      height: 1000,
      title: "Introductionary Uni Project",
      smallTextContent: "Gudcraft: a First semester Uni project -- refurbish a classic game..",
      largeTextContent: function() {
        return (GudcraftCardContent());
    },
      dateFrom: "2021",
      dateEnd: "2022",
      programmingChips: ["C#", "Monogame", "Uni"],
    },

    {
      id: 1,
      relevance: 15.0,
      source: "./img/projects/casinotd.webp",
      width: 200,
      height: 1000,
      title: "Casino TD",
      smallTextContent: "First GameJam! Theme: Gameception",
      largeTextContent: function() {
        return (CasinoTDCardContent());
    },
      dateFrom: "2021",
      dateEnd: "2022",
      programmingChips: ["C#", "Monogame", "GameJam"],
    },

    {
      id: 1,
      relevance: 17.0,
      source: "./img/projects/chumps.webp",
      width: 200,
      height: 1000,
      title: "Boardgame: Chumps",
      smallTextContent: "Two-player strategy boardgame",
      largeTextContent: function() {
        return (ChumpsCardContent());
    },
      dateFrom: "2021",
      dateEnd: "2022",
      programmingChips: ["Boardgame", "Uni"],
    },

    {
      id: 1,
      relevance: 20.0,
      source: "./img/projects/futuro.webp",
      width: 200,
      height: 1000,
      title: "Physical Toy",
      smallTextContent: "Adaptive game for children with motor skill development problems",
      largeTextContent: function() {
        return (futuroCardContent());
    },
      dateFrom: "2021",
      dateEnd: "2022",
      programmingChips: ["C", "Uni"],
    },

    {
      id: 1,
      relevance: 19.0,
      source: "./img/projects/computervision.webp",
      width: 200,
      height: 1000,
      title: "Computer Vision",
      smallTextContent: "3d tracking using multiple cameras",
      largeTextContent: function() {
        return (futuroCardContent());
    },
      dateFrom: "2021",
      dateEnd: "2022",
      programmingChips: ["C++", "OpenCV", "Uni"],
    },

    {
      id: 1,
      relevance: 55.0,
      source: "./img/projects/hotelpluhz.webp",
      width: 200,
      height: 1000,
      title: "Hotel Pluhz",
      smallTextContent: "A serious game focused on livestyle improvement.",
      largeTextContent: function() {
        return (HotelPluhzCardContent());
    },
      dateFrom: "2021",
      dateEnd: "2022",
      programmingChips: ["C#", "Unity", "Uni"],
    },

    {
      id: 1,
      relevance: 40.0,
      source: "./img/projects/alienlost.webp",
      width: 200,
      height: 1000,
      title: "Alien Lost",
      smallTextContent: "GlobalGameJam 2020 submission",
      largeTextContent: function() {
        return (AlienLostCardContent());
    },
      dateFrom: "2021",
      dateEnd: "2022",
      programmingChips: ["C#", "Unity", "GameJam"],
    },

    {
      id: 1,
      relevance: 50.0,
      source: "./img/projects/wtfdog.webp",
      width: 200,
      height: 1000,
      title: "Wtf Dog?!",
      smallTextContent: "GlobalGameJam 2021 submission",
      largeTextContent: function() {
        return (WtfDogCardContent());
    },
      dateFrom: "2021",
      dateEnd: "2022",
      programmingChips: ["C#", "Unity", "GameJam"],
    },

    {
      id: 1,
      relevance: 30.0,
      source: "./img/projects/tributes.webp",
      width: 200,
      height: 1000,
      title: "Tributes",
      smallTextContent: "A networked game using Unity for Netcode and the Unity Game Services.",
      largeTextContent: function() {
        return (TributesCardContent());
    },
      dateFrom: "2022",
      dateEnd: "2022",
      programmingChips: ["Unity", "Networking"],
    },

    {
      id: 1,
      relevance: 20.0,
      source: "./img/projects/ledstrip.webp",
      width: 200,
      height: 1000,
      title: "Controllable Ledstrip",
      smallTextContent: "Ledstrip controller hosted on a raspberry pi",
      largeTextContent: function() {
        return (LedStripCardContent());
    },
      dateFrom: "2022",
      dateEnd: "2022",
      programmingChips: ["Python", "Hardware", "C#"],
    },

    {
      id: 1,
      relevance: 15.0,
      source: "./img/projects/twomenonice.webp",
      width: 200,
      height: 1000,
      title: "Rigidbody Game Physics",
      smallTextContent: "Custom Rigidbody physics engine w/ collision detecting, collision resolution, friction forces, and external forces.",
      largeTextContent: function() {
        return (LedStripCardContent());
    },
      dateFrom: "2019",
      dateEnd: "2019",
      programmingChips: ["C#", "Uni"],
    },

    {
      id: 1,
      relevance: 55.0,
      source: "./img/projects/terminus1.webp",
      width: 200,
      height: 1000,
      title: "Terminus",
      smallTextContent: "Deploying and maintaining an Applied Game for the Police Academy",
      largeTextContent: function() {
        return (TerminusCardContent());
    },
      dateFrom: "2021",
      dateEnd: "2022",
      programmingChips: ["C#", "Unity", "App"],
    },

    {
      id: 1,
      relevance: 60.0,
      source: "./img/projects/kendo.webp",
      width: 200,
      height: 1000,
      title: "Ken-do",
      smallTextContent: "Assessing and ameliorating sensory processing deficits of children with Autism Spectrum Disorder employing a serious rhythmic game",
      largeTextContent: function() {
        return (KendoCardContent());
    },
      dateFrom: "2021",
      dateEnd: "2022",
      programmingChips: ["C#", "Unity", "App"],
    },

    {
      id: 1,
      relevance: 60.0,
      source: "./img/projects/thesis.webp",
      width: 200,
      height: 1000,
      title: "Game-based Personality Assessment",
      smallTextContent: "Master Thesis on measuring conscientiousness using a wide variety of mini-games.",
      largeTextContent: function() {
        return (MasterThesisCardContent());
    },
      dateFrom: "2021",
      dateEnd: "2022",
      programmingChips: ["C#", "Unity", "Research"],
    },
  ];

  function MasterThesisCardContent() {
    return (
      <div>
        <h1>Towards Game-based Personality Assessment</h1>
        <p>
          Personality is one of the key influencing factors in behavior; measuring behavior gives an insight into personality. This behavior is measurable in many different media, including this research's focus: video games. This research creates a set of mini-games and compares the in-game behavior with conscientiousness and its six facets, measured using the Five-Factor Model IPIP-120 personality questionnaire. These mini-games are designed specifically to maximize the influence of conscientiousness on game behavior. The results show 33 significant linear relationships between the independent in-game met- rics and the facets and six directly with conscientiousness. Additionally, both linear and logistic regression models attempt to predict the players' conscientiousness. Although the linear regression models per level are able to predict some variance per level, the mod- els are not stable enough to base any conclusion on. However, the logistic regression models based on all the levels are able to discriminate, although poorly, between high and low-scoring individuals, for the facets cautiousness and achievement-striving. There- fore, based on the linear correlations and logistic regression models, we conclude that it is possible to design mini-games specifically to measure conscientiousness and its facets. However, we are far from a proper game-based personality assessment; this definitely requires a longer, much more iterated, and fine-tuned game. 
       </p>

       <p>
        <a href="http://gamesome.nl/thesis/">[Read More]</a>
       </p>
      </div>
    );
  }


  function KendoCardContent() {
    return (
      <div>
        <h1>Ken-do [in development]</h1>
        <p>
        <b>Goal of the project:</b> Develop and evaluate a rhythmic game running on a tablet, in order to assess and ameliorate sensory deficits of children with autism spectrum disorder (ASD).
        </p>
        <p>
        <b>Project description:</b> Children with ASD have problems with processing stimuli from their environment, which causes problems for their ability to play and communicate with others. Recent studies indicate that therapeutic interventions based on musical rhythms help detecting these sensory processing challenges, and can improve planning and execution strategies of children with ASD. The game will enable to gather tapping data from children playing along a series of musical rhythms with different complexity on a tablet, and to train children’s rhythmic skills in a playful manner. Gathering data on a large scale with this rhythmic game, will allow us to corroborate previous findings on temporal deficits of children with ASD and to pave the way for tailor-made interventions that emphasize the execution and control of movements at the sensorimotor level, optimized for an individual child. This interdisciplinary project is carried out in a collaboration between our department, the UMCU and Leiden University.
       </p>

       <p>
        Worked as the sole developer responsible for the game design, game development, and backend of this game.
       </p>
      </div>
    );
  }

  function PromptedCardContent() {
    return (
      <div>
        <h1>Prompted</h1>
        <p>
            To be announced!
       </p>
      </div>
    );
  }

  function TerminusCardContent() {
    return (
      <div>
        <h1>Terminus</h1>
        <p>
          Hired as a Unity developer to finalise and deploy an 'almost functional' training game
        commissioned by the Police Academy. Key responsibilities included studying the
        existing source code to identify and resolve major bugs, improving UI and gameplay,
        and modifying certain functions in the existing JS backend.
       </p>
      </div>
    );
  }

  function LedStripCardContent() {
    return (
      <div>
        <h1>Controllable Ledstrip</h1>
        <p>
        In my LED strip project, I employed a Raspberry Pi to run a Python-based server, which serves as the central hub for controlling the LED strip. Each diode on the LED strip is individually addressed and can be manipulated through specific commands. This project features a range of captivating effects, including a dynamic "bitcoin" mode that turns all LEDs red during market downturns, creating a visual representation of cryptocurrency market trends. Additionally, it boasts a lively party mode for festive occasions and an ambient ambilight mode, specifically designed to run around my TV, enhancing the viewing experience with synchronized and immersive lighting effects. The server's capability to listen and respond to commands allows for a customizable and interactive LED strip experience, offering both functionality and entertainment.
       </p>
      </div>
    );
  }

  function TributesCardContent() {
    return (
      <div>
        <h1>Tributes</h1>
        <p>
          Abandoned Project exploring Unity Networking..
       </p>
      </div>
    );
  }

  function AlienLostCardContent() {
    return (
      <div>
        <h1>An Alien Lost..</h1>
        <p>
          GlobalGameJam 2020 (team) submission -- play the game <a href="https://chartle.itch.io/wtf-dog">[here]</a>
        </p>
      </div>
    );
  }

  function WtfDogCardContent() {
    return (
      <div>
        <h1>Wtf Dog?!!</h1>
        <p>
          GlobalGameJam 2021 (team) submission -- play the game <a href="https://chartle.itch.io/wtf-dog">[here]</a>
        </p>
      </div>
    );
  }

  function SpaceFreightCardContent() {
    return (
      <div>
        <h1>Space Freight Ltd.</h1>
        <p>
          LudumDare 54 (team) submission -- play the game <a href="https://chartle.itch.io/space-freight-ltd">[here]</a>
        </p>
      </div>
    );
  }

  function ZalfjeCardContent() {
    return (
      <div>
        <h1>Zalfje</h1>
        <p>
        I served as the sole developer and designer for Zalfje, where the primary objective is to embark on a journey towards becoming expert skin-care enthusiasts. In the game, Mr. Zalf challenges the player to prove their expertise in treating eczema, believing himself to be the ultimate authority on the subject. Through various engaging games and components within the app, players have the opportunity to collect trophies. The ultimate goal is to attain all the trophies, signifying the mastery of eczema treatment, and to become a team with Zalfje, earning the title of "smeer champion" (cream champion). Each location in the game features a unique minigame with an animated explanation of the learning objective associated with that specific game. 
        </p>
        <p>
        <CardMedia
          component="img"
          src="./img/projects/zalfje2.webp"
          title="zalfje_screenshot_2"
        />
        </p>
        <p>
        <CardMedia
          component="img"
          src="./img/projects/zalfje3.webp"
          title="zalfje_screenshot_3"
        />
        </p>
        <p>
        <CardMedia
          component="img"
          src="./img/projects/zalfje4.webp"
          title="zalfje_screenshot_4"
        />
        </p>
      </div>
    );
  }

  function SurveyToolkitCardContent() {
    return (
      <div>
        <h1>Survey Toolkit</h1>
        <p>
          The SurveyToolkit is a software tool designed for plug-and-play generation of questionnaires and surveys for applications across VR, Desktop, and Mobile.
          This tool offers a non-scripting solution to create beautiful questionnaires & surveys. Furthermore, it includes a straightforward instructional manual detailing the setup procedure for a basic backend system, which stands prepared for data collection online, if deemed necessary. Consequently, developers can allocate their efforts toward addressing other critical, application-specific functionalities.
          The SurveyToolkit supports the following question types: Open, Linear, Grid, Slider, Checkbox and Multiple Choice.
        </p>
      </div>
    );
  }

  function HotelPluhzCardContent() {
    return (
      <div>
        <h1>A serious game..</h1>
        <p>
        Many people with a chronic illness refrain from forming healthier lifestyle habits. They tend to visit the hospital more often, resulting in higher health-care costs. To reduce these costs, these people have to be motivated to improve their lifestyle. One way to achieve this is to make a video game that rewards them for doing activities that promote healthier habits. This project researches the viability of a game whose main focus is not on living healthier. Instead, players can receive in-game bonuses by completing real life challenges which promote a healthier lifestyle. These challenges are created by practitioners using a secondary application that communicates with the game.
        </p>

        <CardMedia
          component="iframe"
          image="https://www.youtube.com/embed/SjEVp82hfao"
          height="400"
        />
      </div>
    );
  }
  
  function futuroCardContent() {
    return (
      <div>
        <h1>Adaptive Game</h1>
        <p>
          soon!
        </p>
      </div>
    );
  }
  
  function ChumpsCardContent() {
    return (
      <div>
        <h1>Chumps</h1>
        <p>
          Boardgame created for a design course. The game is based loosely on Stratego. In this game are units the sum of the alotted components, which give abilities and strengths. The twist is that as long as you dont use a certain component you do not have to show it; as a result the players have to deduce the strengths of the other players' units.  
        </p>
      </div>
    );
  }

  function CasinoTDCardContent() {
    return (
      <div>
        <h1>Casino TD</h1>
        <p>
          Game created for a local gamejam with the theme gameception. The game has a skill-based dropping turrets/upgrade mechanic based on a grab vending machine. The turret or upgrade to drop is based on a slot machine. This was actually a very fun game, therefor a remastered is on my to-do list. Or actually one is already in the makings, but the project is dead at the moment. lol.
        </p>
      </div>
    );
  }

  function GudcraftCardContent() {
    return (
      <div>
        <h1>Gudcraft</h1>
        <p>
          The project was to re-create a classic game. We chose to re-create starcraft 1, we found out the hard way that 3 students were not able to finish a RTS game in 9 weeks. Our version did however feature 1 race, 1 map, in an endless mode. Moreover, the game was designed to be played with a controller.
          </p>
      </div>
    );
  }


  function PazookaCardContent() {
    return (
      <div>
        <h1>Highschool Project</h1>
        <p>
          This is an old gem from back in my highschool days. For the final research project I ""researched"" how to make a game (by creating one). This led me to create this insane 2d platformer.
        </p>
        <p>
          Features:
         <ul>
            <li>A santa claus with a bazooka ✔</li>
            <li>In-game map editor!</li>
            <li>Custom dubstep soundtrack</li>
            <li>Freshly ripped art assets such as the pokemon; ghastly</li>
          </ul>
        </p>
        <p>
        </p>
        <CardMedia
          component="video"
          controls
          src="./img/projects/pazooka.mp4"
        />
      </div>
    );
  }

  function PickinSticksCardContent() {
    return (
      <div>
        <h1>My first game ever,</h1>
        <p>
          This one holds a special place in my heart as it is the first game I created. This was back in 2010 when I was only 15 years old. It is created in C++ with the SDL graphics library. I do have to admit I didn't really get the C pointers, lol.
          </p>
      </div>
    );
  }


  

  function ReactCardContent() {
    return (
      <div>
        <h1>Hi,</h1>
        <p>
          A portfolio for me to track my own work and expose it to others was long overdue. In the summer of 2022 after finishing my masters was the time finally there. A portfolio site is born, it's created in ReactJS with MaterialUI. Even though my Javascript knowledge is limited, and learned to like it a bit more, I still have to admit, I dislike it 👀.
        </p>
        <p>
           Hope you enjoy the creations on my page!
        </p>
      </div>
    );
  }

  function VedeaCardContent() {
    return (
      <div>
        <h1>Vedea</h1>
        <p>
          Worked as the lead developer for Vedea. Vedea is dedicated to developing a scientifically validated treatment method for children with amblyopia, a visual disorder affecting visual acuity. By exploring novel ideas regarding neural mechanisms and implementing innovative therapies, Vedea has achieved positive outcomes. Over the past decade, the potential of dichoptic gaming as an effective intervention for amblyopia has been clinically tested and confirmed. Vedea is bringing this approach to fruition by creating a software application for VR smartphones, enabling children to engage in special games and exercises. We, at Vedea, are the creators of this game-changing treatment method, focusing on children aged 5 to 9, as this age range offers the optimal likelihood of effective intervention and significant improvements in quality of life.
        </p>
        <p>
           
        </p>
      </div>
    );
  }

  function SandSimCardContent() {
    return (
      <div>
        <h1>Sand Simulation</h1>
        <p>
           Played around a little with compute shaders, created a 2d sand simulation. The complete simulation runs on the GPU, only the input runs on the CPU. Capable of running at near lightspeed ;)
       </p>
        <p>
           
        </p>
      </div>
    );
  }

  function TheWallCardContent() {
    return (
      <div>
        <h1>The Wall</h1>
        <p>
          "The Wall" is a technical sample project where players demolish a regenerating wall painting, transformed into a 3D model using marching cubes. The regeneration is handled by an imported model using Sentis.
        </p>
      </div>
    );
  }

  function DotsSurvivorCardContent() {
    return (
      <div>
        <h1>DOTS-Survivor</h1>
        <p>
        Dots-Survivor is a top-down survivor-genre game developed with the Unity ECS framework, offering players an intense colorful experience. What sets it apart is its remarkable capability to handle tens of thousands of concurrent enemies, showcasing the efficiency and scalability of ECS technology. In this gripping game, players navigate through hordes of foes, relying on strategic survival skills to overcome overwhelming odds. Dots-Survivor not only provides a challenging and dynamic gameplay experience but also demonstrates the power of Unity ECS in creating high-performance games with massive enemy encounters. It was originally created as a learning project, but it slowly becoming more of a game.
        </p>
        <p>
           
        </p>
      </div>
    );
  }

  function DeployHelperBotCardContent() {
    return (
      <div>
        <h1>Deploy Helper Bot</h1>
        <p>
            Ludum Dare 51 submission
        </p>
        <p>
            Ludum Dare 51 submission -- play the game <a href="https://chartle.itch.io/deploy-helper-bot">[here]</a>
        </p>
      </div>
    );
  }

  function WindesheimCardContent() {
    return (
      <div>
        <h1>NurseInVR</h1>
        <p>
        During my tenure at GainPlay Studio, I had the privilege of serving as the lead developer for the creation of NursInVR, a virtual reality (VR) game designed specifically to enhance nurses' skills in patient interaction. This innovative project aimed to provide an immersive and dynamic learning experience for healthcare professionals by simulating various patient scenarios within a virtual environment. As the lead developer, my role involved spearheading the technical aspects of the game's development, ensuring the seamless integration of VR technology and creating engaging and realistic scenarios. NursInVR represents a significant stride in utilizing gaming technology as an effective tool for training healthcare professionals, offering a hands-on approach to honing crucial patient interaction skills in a virtual setting.
        </p>
      </div>
    );
  }

  function PathTracerContent() {
    return (
      <div>
        <h1>C++ Path Tracer</h1>
        <p>
        Path Tracing
The path tracer uses the BVH for intersecting geometry, and supports reflective, refractive and diffuse bounces.

Lighting
Multiple Point Lights and Area Lights can be placed in a scene and are sampled correctly using PNEE, IS, or random bounces.

Importance Sampling
Cosine weighted hemisphere sampling is used for the hemisphere sampling of diffuse bounces.
Russian Roulette is implemented to (sometimes) kill the paths that are expected to return low energy values.
Next Event Estimation is used to sample the lights directly and separately with 50% of the rays, as they are expected to return the most light.
Importance Sampling calculates the potential contribution of each light source, and uses this as a probability for sampling each light.

Adaptive Sampling
Adaptive Sampling was implemented based on the individual pixel variance (quadratic distance), as per the paper of M. Sik.

PNEE Grid
As per the Master’s Thesis of Andreas Mikolajewski.

Basic process: First a configurable amount of photons is sent from each light source to the scene. Each 3D grid cell saves from which light source the photons arrived. This information is then used as a weight for Importance Sampling.

DoF (post-process)
A Gaussian and Box Blur filter were implemented to create a post-processing Depth of Field, based on the distance of the nearest intersection for each pixel to the camera.
        </p>
        <p>
           Hope you enjoy the creations on my page!
        </p>
          <CardMedia
            component="img"
            image="./img/projects/pathtracer2.webp"
            title="pathtracer"
          />
      </div>
    );
  }
  
  export default cardDataList;
  